export type CookieConsentType = "all" | "necessary";

export function displayCookieBanner(): boolean {
  const cookieConsent = getCookieConsent();
  return cookieConsent === null ? true : false;
}

let resolveOptedIn

export function setCookieConsent(consentValue: CookieConsentType) {
  localStorage.setItem("cookieConsent", consentValue);
  resolveOptedIn?.(consentValue)
}

export function getCookieConsent(): CookieConsentType | null {
  return localStorage.getItem("cookieConsent") as CookieConsentType | null;
}

export const analyticsOptedIn = new Promise((resolve, reject) => {
  resolveOptedIn = (consent)=>{ if(consent === "all") resolve(true); }
  resolveOptedIn(getCookieConsent());
});
