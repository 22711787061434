<script lang="ts">
import { reactiveLocation } from '../lib/railsUtil.svelte';
import Form from './Form.svelte';

let {
  links,
  logo_path,
  case_type,
  unread_messages,
  unread_news,
} : {
  links: { [key: string]: [title: string, path: string, icon: string | null, linkAttrs: {}] };
  logo_path: string;
  case_type: string;
  unread_messages: { [key: string]: string | null };
  unread_news: { [key: string]: number };
} = $props()

let expanded = $state(false)
$effect(()=>document.body.classList[expanded ? "add" : "remove"]("overflow-hidden","h-full"))

const hasUnread = (key)=>{
  switch(key){
    case "messages":
      return Object.keys(unread_messages).length > 0;
    case "news":
      return Object.keys(unread_news).length > 0;
    default:
      return false;
  }
}
const currentLocation = reactiveLocation()
const isCurrent = (key, path)=>{
  if(key == "home") return path == currentLocation.pathname;
  return currentLocation.pathname.startsWith(path);
}
</script>

{#snippet navLink(key, [title, path, icon, linkAttrs])}
  {@const {class:linkClass, ...attrs} = (linkAttrs || {})}
  <span class:new={hasUnread(key)} class:active={isCurrent(key, path.replace(/\?.*$/, ""))}>
    <a class="nav-link {linkClass||''}" href={path} {...attrs} onclick={()=>expanded = false}>
      {#if icon}<img src={icon} alt=""/>{/if}
      {title}
    </a>
  </span>
{/snippet}

<turbo-frame id="navigation-frame" target="_top" class:show-nav={expanded}>
  <div class="navigation flex justify-between items-center bg-white sticky top-0 z-20">
    <a href="/"><img src={logo_path} alt="Johnson Law Group" class="logo"></a>
    
    <button type="button" id="menu" onclick={()=>expanded = !expanded} aria-expanded="{expanded}">
      <span class="sr-only">Expand menu</span>
      {#if hasUnread("messages") || hasUnread("news")}
        <span class="notification-dot" class:opacity-0={expanded}></span>
      {/if}
    </button>
    
    <h2>Your {case_type} Case</h2>

    <div class="navigation-links">
      {#each Object.entries(links) as [key, link]}
        {#if key.startsWith("_")}
          <span class="h-0.5 !p-0 !m-0 flex-grow flex-shrink"></span>
        {:else if key == "logout"}
          {@const [title, path, icon] = link as Array<string>}
          <span>
            <Form action={path} method="delete">
              <button class="nav-link" type="submit">
                {#if icon}<img src={icon} alt=""/>{/if}
                {title}
              </button>
            </Form>
          </span>
        {:else}
          {@render navLink(key, link)}
        {/if}
      {/each}
    </div>
  </div>
</turbo-frame>

<style lang="postcss">
.notification-dot {
  @apply absolute bg-warning rounded-full w-3 h-3 -top-2 -right-1 transition-opacity;
}

.navigation {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid rgb(235, 235, 235);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 25px;
  position: sticky;
  top: 0;
  z-index: 11;

  .logo {
    margin: 0;
    z-index: 100;
    position: relative;
    @apply w-32 lg:w-[180px];
     /*
      * I apologize for these negative margin shenanigans. This is the least intrusive
      * way to get the mobile layout right without changing padding settings that affect
      * desktop too. Let's do it right when we get the new branding.
      */
    margin: -3px 0 -2px -5px;
    @media (min-width: 1050px) {
      margin: 20px 0 30px 23px;
    }
  }

  h2 {
    display: none;
  }
}

@media (min-width: 1050px) {
  .navigation {
    @apply rounded items-start bg-white border-b-0 flex-col justify-stretch p-0 w-60 fixed top-[unset] overflow-y-auto;
    height: calc(100vh - 32px);
  }
}
.navigation-links {
  display: none;

  @media (min-width: 1050px) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
  }

  span {
    margin-bottom: 10px;

    &.active {
      background-color: #E7EAEF;
      position: relative;

      &:before {
        @apply bg-blue h-12 w-1 absolute left-0 top-0;
        content: '';
      }
    }
  }


  .new {
    position: relative;

    &::after {
      content: "";
      @apply bg-warning;
      border-radius: 100px;
      display: inline-block;
      height: 12px;
      margin-left: 5px;
      width: 12px;
      position: absolute;
      top: 7px;
      left: 35px;
    }
  }
}


.nav-link {
  @apply text-blue items-center flex h-12 pl-[25px] w-full no-underline font-normal hover:bg-[#E7EAEF];
}

.nav-link img {
  margin: 0 10px 2px 0;
  width: 22px;
  height: auto;
}

#menu {
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  border-top:2px solid #000;
  box-sizing:border-box;
  cursor:pointer;
  height:25px;
  min-width: 30px;
  padding: 0;
  position:absolute;
  right:25px;
  transition: all 0.3s ease-in;
  top:19px;
  width:30px;
  z-index:30;

  &:before {
    background: #000;
    content:'';
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    top: 10px;
    transition: all 0.3s ease-in;
    width: 30px;
  }

  &:after {
    background: #000;
    bottom: 0;
    content:'';
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    transition: all 0.3s ease-in;
    width: 30px;
  }

  @media (min-width: 1050px) {
    display: none;
  }
}

.show-nav{
  #menu {
    border-color:transparent;
    
    &:before {
      background: #000;
      left:-2px;
      transform:rotate(45deg);
      width:33px;
    }

    &:after {
      background: #000;
      bottom:11px;
      left:-2px;
      transform: rotate(135deg);
      width:33px;
    }
  }

  .navigation{
    position: fixed;
    width: 100%;

    &:before {
      bottom: 0;
      background-color: #fff;
      content: '';
      display: block;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 1;
    }

    h2 {
      display: block;
      z-index: 100;
      position: absolute;
      top: 80px;
    }
  }

  .navigation-links {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 125px;
    bottom: 0;
    width: 100%;

    span {
      margin: 5px 0 0;
      @media (min-height: 620px) {
        margin: 15px 0 0;
      }
    }

    .nav-link {
      color: #000;
      margin: 0;
      width: 100%;
    }
  }
}
</style>