<script lang="ts">
  import ListChecks from "~icons/ph/list-checks";
  let {
    mobile,
    todo_ids
  } : {
    mobile: boolean,
    todo_ids: { [key: string]: number }
  } = $props()

  let count = $derived(Object.keys(todo_ids).length)
  let any = $derived(count > 0)
</script>
{#if mobile}
  Tasks To Do<span
    class="text-black rounded-full flex items-center justify-center w-7 h-7 ml-2"
    class:bg-warning-200={any}
    class:bg-base-200={!any}
  >{count}</span>
{:else if any}
  <div class="alert alert-warning bg-warning-200 border-l-4 border-0 border-solid border-warning hidden lg:grid justify-between">
    <ListChecks class="w-6 h-6" />
    <span>Tasks To Do</span>
    <span class="rounded-full bg-white font-medium text-lg h-8 w-8 flex justify-center items-center">{count}</span>
  </div>
{/if}