<script lang="ts">
  import { scale } from "svelte/transition";

  let {
    type: railsType = "notice",
    message,
    shadow = false,
    autohide = null,
  } : {
    type : string,
    message : string,
    shadow : boolean,
    autohide : number | false | null,
  } = $props()

  let type = {
    alert: "warning"
  }[railsType] || railsType
  
  // autohide if an autohide timeout has been provided or if type="notice|success" (and there's no explicit autohide={false})
  if(autohide === null) autohide = (type == "notice" || type == "success") && 5_000
  
  let dismissed = $state(true)
  const dismiss = ()=>dismissed = true;

  $effect(()=>{
    // Transition in 0.2s after mounting
    setTimeout(()=>dismissed = false, 200)
    if(autohide) {
      let timeout = setTimeout(dismiss, autohide)
      return ()=>clearTimeout(timeout)
    }
  });
</script>
{#if !dismissed}
  <div
    class="alert relative rounded-lg"
    class:alert-error={type == "error"}
    class:alert-warning={type == "warning"}
    class:alert-success={type == "success"}
    class:alert-info={type == "info"}
    class:shadow-md={shadow}
    transition:scale={{duration: 150, start: 0.75}}
  >
    {#if type == "error"}
      <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current hidden sm:inline-block shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
    {:else if type == "warning"}
      <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current hidden sm:inline-block shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
    {:else if type == "success"}
      <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current hidden sm:inline-block shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
    {:else if type == "notice"}
      <svg xmlns="http://www.w3.org/2000/svg" class="stroke-info hidden sm:inline-block shrink-0 w-6 h-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
    {:else if type == "info"}
      <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current hidden sm:inline-block shrink-0 w-6 h-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
    {/if}
    <span class="pr-8 sm:pr-0">{message}</span>
    <button
      type="button"
      class="btn btn-sm btn-circle btn-ghost absolute sm:static right-2"
      aria-label="Dismiss"
      onclick={dismiss}
    >✕</button>
  </div>
{/if}
