<script lang="ts">
  import type { Snippet } from 'svelte';
  import { csrfToken } from "../lib/railsUtil.svelte";
  let {
    action = "",
    method: logicalMethod = "get",
    children,
    ...props
  } : {
    action : string;
    method : "get" | "post" | "patch" | "put" | "delete";
    children : Snippet;
  } = $props()
  let [method, railsMethod] = ["get","post"].includes(logicalMethod.toLowerCase()) ? [logicalMethod, null] : ["post", logicalMethod]
</script>
<form {action} {method} {...props}>
  {#if railsMethod}
    <input type="hidden" autocomplete="off" name="_method" value={railsMethod}>
  {/if}
  {@render children()}
  {#if method.toLowerCase() !== "get"}
    <input type="hidden" autocomplete="off" {...csrfToken()}>
  {/if}
</form>
